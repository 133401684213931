import firebase from "firebase"
import { isBrowser } from "../../../services/general"

export const createAddressDocument = async ({ values }) => {
  let newAddressDocument = {
    addresses: [
      {
        type: values?.deliveryAddress?.addressType,
        streetAddress: values?.deliveryAddress?.streetAddress,
        city: values?.deliveryAddress?.city?.value,
        province: values?.deliveryAddress?.province?.value,
        barangay: values?.deliveryAddress?.barangay?.value,
        primary: true,
      },
    ],
  }

  let addressResponse = await firebase
    .firestore()
    .collection("addresses")
    .add({ ...newAddressDocument })

  let addressesUid = addressResponse.id

  if (isBrowser()) {
    sessionStorage.setItem(
      "addresses",
      JSON.stringify({ ...newAddressDocument, id: addressesUid })
    )
  }

  return addressResponse
}

export const updateAddressDocument = async ({
  values,
  addressesUid,
  oldAddresses,
}) => {
  let updatedAddressDocument = {
    addresses: [
      ...oldAddresses,
      {
        type: values?.deliveryAddress?.addressType,
        streetAddress: values?.deliveryAddress?.streetAddress,
        city: values?.deliveryAddress?.city?.value,
        province: values?.deliveryAddress?.province?.value,
        barangay: values?.deliveryAddress?.barangay?.value,
        primary: true,
      },
    ],
  }

  await firebase
    .firestore()
    .collection("addresses")
    .doc(addressesUid)
    .update({ ...updatedAddressDocument })

  if (isBrowser()) {
    sessionStorage.setItem(
      "addresses",
      JSON.stringify({ ...updatedAddressDocument })
    )
  }
}

export const updateUserDocument = async ({
  user,
  addressesUid,
  hasUploadedValidID,
  numberOfTimesHasUploadedID,
  authUid,
}) => {
  let newUserDocument = {
    email: user?.email,
  }

  if (addressesUid) newUserDocument.addresses = addressesUid

  if (hasUploadedValidID) {
    newUserDocument.hasUploadedValidID = true
    let newNumber = parseInt(numberOfTimesHasUploadedID) + 1
    newUserDocument.numberOfTimesHasUploadedID = newNumber
  }

  if (authUid) {
    newUserDocument.authUid = authUid
  }

  await firebase
    .firestore()
    .collection("users")
    .doc(user?.id)
    .update({ ...newUserDocument })
}
